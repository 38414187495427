<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <i class="flaticon2-group icon-lg"></i>
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Teamwork API Settings</h3>
                  <div class="wizard-desc">Setup your API Credentials</div>
                </div>
              </div>
            </div>
            <div
              v-if="currentUserAccountInfo.admin"
              class="wizard-step"
              data-wizard-type="step"
            >
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <i class="flaticon2-map icon-lg"></i>
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Arcgis Setting</h3>
                  <div class="wizard-desc">Setup your API Credentials</div>
                </div>
              </div>
            </div>
            <div
              v-if="currentUserAccountInfo.admin"
              class="wizard-step"
              href="#"
              data-wizard-type="step"
            >
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <i class="flaticon2-email icon-lg"></i>
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Email SERVER SETUP</h3>
                  <div class="wizard-desc">Add Your SMTP Information</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 class="mb-10 font-weight-bold text-dark">
                  Enter your Teamwork API Information&nbsp;
                  <b-badge
                    href="https://apidocs.teamwork.com/docs/teamwork/d1b2de52c3cec-api-key-and-url"
                    target="_blank"
                    pill
                    variant="primary"
                  >
                    ?
                  </b-badge>
                </h4>
                <div class="form-group">
                  <label>Teamwork URL</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="fname"
                    placeholder="Teamwork URL"
                    v-model="teamwork_data.teamwork_url"
                  />
                  <span class="form-text text-muted"
                    >Example: https://sample.teamwork.com/</span
                  >
                </div>
                <div class="form-group">
                  <label>API Token</label>
                  <input
                    type="password"
                    class="form-control form-control-solid form-control-lg"
                    name="api_token"
                    placeholder="API TOKEN"
                    v-model="teamwork_data.teamwork_api_token"
                  />
                  <span class="form-text text-muted"
                    >Example: tmp_testesd23423434.</span
                  >
                </div>
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      @click="testAPI('teamwork', teamwork_data)"
                      :disabled="!(teamwork_data.teamwork_url && teamwork_data.teamwork_api_token)"
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    >
                      Test
                    </button>
                    <span
                      class="pl-2"
                      v-if="teamwork_data.test_status == undefined"
                    >
                      You should test before saving information</span
                    >
                    <span
                      class="pl-2 text-danger"
                      v-if="teamwork_data.test_status == false"
                    >
                      Your Teamwork API information is incorrect! Please, check
                      this
                      <a
                        href="https://apidocs.teamwork.com/docs/teamwork/d1b2de52c3cec-api-key-and-url"
                        target="_blank"
                        >link</a
                      >.</span
                    >
                    <span
                      class="pl-2 text-primary"
                      v-if="teamwork_data.test_status == true"
                    >
                      Your Teamwork API information is correct! You can save it
                      now.</span
                    >
                  </div>
                  <div class="mr-2">
                    <button
                      @click="saveData(teamwork_data)"
                      :disabled="!(teamwork_data.teamwork_url && teamwork_data.teamwork_api_token && teamwork_data.test_status)"
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Enter your Arcgis API Credentials&nbsp;
                  <b-badge
                    href="https://developers.arcgis.com/rest/users-groups-and-items/authentication.htm"
                    target="_blank"
                    pill
                    variant="primary"
                  >
                    ?
                  </b-badge>
                </h4>
                <div class="form-group pt-8 mt-8">
                  <label>Arcgis Access Token</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    name="api_token"
                    placeholder="Arcgis Access Token"
                    v-model="arcgis_data.arcgis_access_token"
                  />
                  <span class="form-text text-muted"
                    >Example:
                    AAPK8ee5c4cce3asdf545a48dc8ff7ea9dcesdfd7cbcDKQRBPlSPvsomsdfiycT__Uoq66VcLNFoJjssdlQT1sdffq3ga748uM4Yy_pH91yAseFQk.</span
                  >
                </div>
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2"></div>
                  <div class="mr-2">
                    <button
                      @click="saveData({'arcgis_access_token': arcgis_data.arcgis_access_token})"
                      :disabled="!(arcgis_data.arcgis_access_token)"
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Enter your STMP Information&nbsp;
                  <b-badge
                    href="https://sendgrid.com/blog/what-is-an-smtp-server/"
                    target="_blank"
                    pill
                    variant="primary"
                  >
                    ?
                  </b-badge>
                </h4>
                <div class="form-group">
                  <label>SMTP HOST:</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="SMTP Host"
                    v-model="smtp_data.smtp_host"
                  />
                  <span class="form-text text-muted"
                    >Example: smtp.serveraddress.com</span
                  >
                </div>
                <div class="form-group">
                  <label>SMTP Port:</label>
                  <input
                    type="number"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="SMTP Port"
                    v-model="smtp_data.smtp_port"
                  />
                  <span class="form-text text-muted">Example: 25</span>
                </div>
                <div class="form-group">
                  <label>SMTP Secured Connection:</label>
                  <b-checkbox v-model="smtp_data.smtp_secured_connection" />
                  <span class="form-text text-muted"
                    >Example: true or false</span
                  >
                </div>
                <div class="form-group">
                  <label>SMTP Username:</label>
                  <input
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="SMTP Username"
                    v-model="smtp_data.smtp_username"
                  />
                  <span class="form-text text-muted"
                    >Example: test@smtp.serveraddress.com</span
                  >
                </div>
                <div class="form-group">
                  <label>SMTP Password:</label>
                  <input
                    type="password"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="SMTP Passowrd"
                    v-model="smtp_data.smtp_password"
                  />
                  <span class="form-text text-muted"
                    >Example: ****************</span
                  >
                </div>
                <div class="form-group">
                  <label>From Email:</label>
                  <input
                    type="email"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="From Email"
                    v-model="smtp_data.smtp_from_email"
                  />
                  <span class="form-text text-muted"
                    >Example: test@smtp.serveraddress.com</span
                  >
                </div>
                <div class="form-group">
                  <label>Test To email:</label>
                  <input
                    type="email"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Test Email"
                    v-model="smtp_data.smtp_test_to_email"
                  />
                  <span class="form-text text-muted"
                    >Example: your email in order to test email</span
                  >
                </div>
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      @click="testAPI('smtp', smtp_data)"
                      :disabled="!(smtp_data.smtp_host && smtp_data.smtp_port && smtp_data.smtp_username && smtp_data.smtp_password && smtp_data.smtp_from_email && smtp_data.smtp_test_to_email)"
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    >
                      Test
                    </button>
                    <span
                      class="pl-2"
                      v-if="smtp_data.test_status == undefined"
                    >
                      You should test before saving information</span
                    >
                    <span
                      class="pl-2 text-danger"
                      v-if="smtp_data.test_status == false"
                    >
                      Your SMTP information is incorrect! Please, check this
                      <a
                        href="https://sendgrid.com/blog/what-is-an-smtp-server/"
                        target="_blank"
                        >link</a
                      >.</span
                    >
                    <span
                      class="pl-2 text-primary"
                      v-if="smtp_data.test_status == true"
                    >
                      Your SMTP information is correct! You can save it
                      now.</span
                    >
                  </div>
                  <div class="mr-2">
                    <button
                      @click="saveData(smtp_data)"
                      :disabled="!(smtp_data.smtp_host && smtp_data.smtp_port && smtp_data.smtp_username && smtp_data.smtp_password && smtp_data.smtp_from_email && smtp_data.test_status)"
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import {mapGetters, mapState} from "vuex";
import {GET_SETTINGS, CREATE_MULTIPLE, API_TESTING} from "@/core/services/store/setting.module";
import Swal from "sweetalert2";

export default {
  name: "transactions",
  components: {
  },
  data() {
    return {
      teamwork_data :  {
        teamwork_url : '',
        teamwork_api_token: '',
        test_status: undefined,
      },
      weatherkit_data :  {
        weatherkit_team_id : '',
        weatherkit_service_id: '',
        weatherkit_key_id: '',
        weatherkit_key_content: '',
        test_status: undefined,
      },
      smtp_data :  {
        smtp_host : '',
        smtp_secured_connection: false,
        smtp_port: '',
        smtp_username: '',
        smtp_password: '',
        smtp_from_email: '',
        smtp_test_to_email: '',
        test_status: undefined,
      },
      arcgis_data:{
        arcgis_client_id : '',
        arcgis_client_secret: '',
        arcgis_access_token: '',
        test_status: undefined,
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.profile.errors
    }),
    ...mapGetters(["currentUserAccountInfo", "layoutConfig"]),
  },
  mounted() {
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    this.getSettings();
  },
  methods: {
    getSettings(){
      this.$store
          .dispatch(GET_SETTINGS)
          .then((data) => {
            this.setData(data)
          });
    },
     testAPI(type, apidata){
      this.$store
          .dispatch(API_TESTING, {type : type, data: apidata})
          .then((data) => {
            if(data.status)
              apidata.test_status = true;
            else
              apidata.test_status = false;
          });
    },
    saveData(apidata){
      this.$store
          .dispatch(CREATE_MULTIPLE, {data: apidata})
          .then((data) => {
             Swal.fire({
                title: "Evocation",
                text: "Your API Information was successfully saved!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary"
             });

          });
    },
    setData(data){
        data.forEach((ele) => {
          switch(ele.key) {
            case 'teamwork_url':
                this.teamwork_data.teamwork_url = ele.value;
                 break;
            case 'teamwork_api_token':
                this.teamwork_data.teamwork_api_token = ele.value;
                 break;

            case 'weatherkit_team_id':
                this.weatherkit_data.weatherkit_team_id = ele.value;
                 break;
            case 'weatherkit_service_id':
                this.weatherkit_data.weatherkit_service_id = ele.value;
                 break;
             case 'weatherkit_key_id':
                this.weatherkit_data.weatherkit_key_id = ele.value;
                 break;
             case 'weatherkit_key_content':
                this.weatherkit_data.weatherkit_key_content = ele.value;
                 break;
             case 'smtp_host':
                this.smtp_data.smtp_host = ele.value;
                 break;
            case 'smtp_secured_connection':
               if(ele.value == "True")
                 this.smtp_data.smtp_secured_connection =  true;
               else
                 this.smtp_data.smtp_secured_connection  = false;
              break;
            case 'smtp_port':
                this.smtp_data.smtp_port = ele.value;
                 break;
            case 'smtp_username':
                this.smtp_data.smtp_username = ele.value;
                 break;
            case 'smtp_password':
                this.smtp_data.smtp_password = ele.value;
                 break;
            case 'smtp_from_email':
                this.smtp_data.smtp_from_email = ele.value;
                 break;
            case 'smtp_test_to_email':
                this.smtp_data.smtp_test_to_email = ele.value;
                 break;
            case 'arcgis_client_id':
                this.arcgis_data.arcgis_client_id = ele.value;
                break;
            case 'arcgis_client_secret':
                this.arcgis_data.arcgis_client_secret = ele.value;
                break;
            case 'arcgis_access_token':
                this.arcgis_data.arcgis_access_token = ele.value;
                break;
            default:
              break;
          }
        })
        this.teamwork_data.test_status = undefined;
        this.weatherkit_data.test_status = undefined;
        this.smtp_data.test_status = undefined;
        this.arcgis_data.test_status = undefined;
    },
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  },
  beforeMount() {

  },
  watch: {
  },
  created() {
  },
};
</script>
<style></style>
